.container {
  .dropzone {

    border-radius: 8px;
    border: dashed 6px $light-grey;
    background-color: $alabaster;
    text-align: center;
    padding: 68px 0 83px;
    width: 423px;

    img {
      margin-bottom: 26px;
    }

    p {

      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.23px;
      text-align: center;
      color: $charcoal-grey;

      &.or {
        font-size: 18px;
      }
    }

    .drop-title {
      display: flex;
      flex-direction: column;
    }


    button {
      padding: 8px 20px;
      width: 159px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: condensed;
      letter-spacing: 0.2px
    }


  }

  .file-info-wrapper {
    margin-top: 48px;
    display: flex;
    align-items: flex-start;

    .file-info-icon {
      margin-right: 19px;
    }

    .file-info-content {
      width: 100%;
      max-width: 365px;

      .file-info-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 13px;
        align-items: center;


        .file-info-title-name-size {

          .file-info-title-name {
            font-size: 15px;
            color: $charcoal-grey;


            .file-info-title-size {

              font-size: 15px;
              font-weight: 300;
              letter-spacing: 0.14px;
              margin-left: 10px;


            }
          }
        }

        .file-info-title-close {
          width: 11px;
          height: 11px;
          cursor: pointer;


        }
      }

      .file-info-text {
        margin-top: 13px;

        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.13px;
        color: $charcoal-grey;

      }
    }

  }
}