
.table-section {
  text-align: center;
  @media (max-width: 750px) {
    padding: 10px;
  }

  .table-content {
    max-width: 1240px;
    height: 100%;
    margin: auto;
    border-radius: 16px;
    border: solid 1px $light-grey;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    background-color: #FCFCFC;
    padding: 5px;

  }
}