.control-measure {
  //transition: all 2s;
  .card {
    text-align: left;
    margin-bottom: 2px;
    display: flex;
    align-items: center;

    .card-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 10px 20px 15px;

      .subtitle {
        font-size: 11px;
        font-stretch: condensed;
        line-height: 1.91;
        color: $hopbush;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
      }


      .state-1 {
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0.23px;
        padding: 0;
        margin: 0;
      }

      .state-2 {
        display: flex;
        flex: 1;
      }

      .state-1, .state-2, .state-3, .state-4 {
        span {
          margin-right: 10px;
        }
      }


    }

    .card-btns {
      width: 114px;
      display: flex;
      position: relative;

      .buttonProgress {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -12px;
        color: $fountain-blue;
      }

      .disable {
        opacity: 0.2;
      }

      .icon-plus {
        margin: 0 14px;
      }

      .card-btns-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .icon-close {
          width: 16px;
          height: 16px;
        }
      }

    }
  }
}