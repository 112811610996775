
.method-statement-modal {

}

.method-statement-modal-main {
  padding: 32px;

  .header-modal {
    margin-bottom: 1px;

    .btn-menu {
      span {
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-stretch: condensed;
        letter-spacing: 0.22px;
        color: $scorpionapprox;
        margin-right: 10px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .bounds {
      max-width: 100%;
      padding: 0 25px;
    }
  }

  .method-statement-modal-content {
    padding: 10px 25px;
    display: flex;
    height: 100vh;
    background-color: $white;

    .title-sidebar {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: normal;
      font-stretch: condensed;
      letter-spacing: 0.21px;
      color: $charcoal-grey;
      margin-bottom: 25px;

      .number-highlights-container {
        background-image: url('../../../assets/imgs/comment.svg');
        width: 42px;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;


        span {

          color: $white;
          margin-top: -9px;
          margin-left: -4px;
        }

      }
    }
  }
}


.sidebar-main {
  overflow: auto;

  transition: all 140ms ease-in;
  //width: 25vw;
}

.sidebar {
  overflow: auto;
  color: rgb(119, 119, 119);
  transition: opacity 140ms ease-in;
  min-height: 80%;
  border: solid 1px $light-grey;
  background-color: $porcelain;
  margin-bottom: 150px;

  .description {
    padding: 25px 25px 0px;

    p {
      padding: 0;
    }
  }
}

.sidebar__highlights {
  padding: 25px;
  font-size: 15px;
}

.highlight__location {
  text-align: right;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.sidebar__highlight {
  padding: 1rem;
  cursor: pointer;
  transition: all 140ms ease-in;
  background-color: $white;
  margin-bottom: 12px;
  border: solid 1px $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: $white;

  .sidebar-card-footer {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.38;
    letter-spacing: normal;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid $light-grey;
    transition: all 140ms ease-in;

    i {
      font-style: italic;
    }

    strong {
      font-weight: 500;
      color: $charcoal-grey;
    }
  }
}


.sidebar__highlight:hover {
  background: rgba(58, 56, 52, 0.08);
  border: solid 1px $fountain-blue;

  .sidebar-card-footer {
    border-top: 1px solid gray;
  }
}


.sidebar-default-text {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0.25px;
  text-align: center;
  color: #c6c5c6;
}

.highlight-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .highlight-action {
    font-size: 11px;

    img {
      width: 20px;
      height: 15px;
    }

    .edit {
      margin-left: 10px;
    }

    .btn {
      width: 60px;
      height: 20px;
      font-size: 12px;
    }

    .btn.btn-primary {
      border: 1px solid #5EBFB2;;
    }
  }
}

.textarea-edit-comment {
  padding: 10px;
  width: 100%;
  color: $charcoal-grey;
}

.PdfHighlighter__tip-container {
  .Tip {
    .Tip__card {

      div div {
        display: none;
      }

      input {
        width: 62px;
        height: 28px;
        background-color: #5ebfb2;
        font-family: Roboto;
        font-size: 12.7px;
        font-weight: bold;
        color: #ffffff;
        margin-left: calc(100% - 62px);
      }

      textarea {
        font-size: 13px;
        font-weight: 300;
        font-style: italic;
        line-height: 1.38;
        color: $charcoal-grey;
      }
    }
  }

}


blockquote {
  padding: 0;
  margin: 0;
  quotes: "\201c" "\201d";
}

blockquote:before {
  content: open-quote;
}

blockquote:after {
  content: close-quote;
}

.Highlight--scrolledTo .Highlight__part {
  background: $fountain-blue !important;
}

//Spinner
.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
