.autocomplete-filter {
  width: 100%;

  .textField-filter {
    padding: 0;
    margin: 0;
    width: 100%;

    label {
      font-size: 14px;

      &.MuiInputLabel-outlined.MuiInputLabel-marginDense {
        transform: translate(0px, 5px) scale(1);
      }

      &.MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(0px, -6px) scale(0.75);
        padding-top: 2px;
      }

      &.MuiFormLabel-root.Mui-focused {
        color: gray;
      }
    }

    .MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
      padding: 0;
      border-radius: 0px;
      border-color: transparent;

      input {
        padding: 0;
        padding-top: 4px;
        margin: 0;
        border: none;
      }

      MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
        right: 0px;
      }
    }

    fieldset {
      border-radius: 0px;

      &.MuiOutlinedInput-notchedOutline {
        border-radius: 0px;

        display: none;
      }

    }
  }
}