.control-measure {
  .card {
    text-align: left;
    margin-bottom: 2px;
    display: flex;
    align-items: center;

    .card-content-feedback {
      display: flex;
      flex: 1;
      padding: 10px 20px 15px;

      span {
        margin-right: 16px;
      }


    }
  }
}