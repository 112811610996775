

.custom-input{

  input{
    border-radius: 0;
    background-color: $porcelain;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 300;
    letter-spacing: 0.15px;

  }

  .MuiFilledInput-underline:before{
    content: none;
  }
  .MuiFilledInput-underline:after{
    display: none;
  }

  .MuiFilledInput-underline.Mui-error:after {
    display: block;
    transform: scaleX(1);
    border: solid 0.5px $tomato;
  }

  label {
    &.Mui-focused.Mui-focused, &.MuiFormLabel-root{
      color: $charcoal-grey;
      font-weight: 300;
      font-size: 14px;
    }

    &.Mui-error, &.Mui-focused.Mui-focused.Mui-error{
      color: $tomato
    }

  }

  p.MuiFormHelperText-root.Mui-error {
    color: $tomato;
  }


}

