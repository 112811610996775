.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-wrap-wrap {

  top: 0;
  bottom: 0;
  display: table;
  width: 100%;
  height: 100%;
  background: url(../../assets/imgs/login_background.jpg) center center no-repeat fixed;
  background-size: cover;

  .login-wrap {

    position: relative;
    z-index: 2;
    display: table-cell;
    vertical-align: middle;

    .login-box {

      width: 308px;
      height: 500px;


      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: $white;

      position: relative;
      overflow: hidden;
      margin: 0 auto;
      transition: opacity 1s !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-size: cover;
      padding: 48px 28px 64px;
      text-align: center;

      .input-login {
        margin-bottom: 15px;
      }


      .btn-login {
        width: 100%;
        padding: 16px 0;
        margin: 12px 0 28px;
      }

    }

    .link-reset-password {

      color: $regent-gray;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.2px;
      cursor: pointer;

    }

    .reset-password-text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.2px;
      margin-top: -15px;
      color: $regent-gray;

    }


  }


}











