.dashboard-table {

  .main-container {

    max-width: 1240px;
    margin: 0 auto;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;

    .table-wrapper {

      padding-top: 35px;

      .paper-container {
        width: 100%;

        .table-container {
          min-width: 650px;

          .table-row-header {
            th {
              background-color: $regent-gray;
              color: $white;
              border-right: solid 1px $regent-gray;
            }

          }

          .row {
            &.removed {
              td {
                color: $mountain-mist;
              }

            }

            .actions-container {
              .btn {
                padding: 2px 10px;
              }
            }
          }


        }

      }

      @media (max-width: 1240px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}


.pagination-container {
  .MuiTablePagination-spacer {
    display: none;
  }

  .MuiTablePagination-input {
    margin-right: 73%;
  }
}

.icon-times-removed {
  opacity: 0.5;
}