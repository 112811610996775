
.results-container {

  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;


  .results-body {
    width: 100%;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;


    .score-container {

      width: 100%;
      height: 74px;
      @include flex-row-center;
      text-align: center;
      margin: 61px 0;
      border-radius: 37px;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      background-color: $white;

      span {

        font-size: 25px;
        font-weight: 300;
        letter-spacing: 0.24px;
        color: $emperor;
      }

    }

    .activities-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .activities-content {

        img {
          width: 100%;
          height: auto;
        }

        h5 {
          span {
            font-size: 18px;
            letter-spacing: 0.17px;

          }
        }

        .result-value {
          margin-top: 35px;

          max-width: 181px;
          width: 100%;
          height: 60px;

          @include flex-row-center;
          border-radius: 37px;


          span {
            font-size: 32.4px;
            font-weight: 300;
            letter-spacing: 0.31px;
            color: $white;
          }

          &:before {
            content: "";
            position: absolute;
            margin-top: -36px;
            width: 0;
            height: 0;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;

          }

          &.hazard {
            background-color: $picton-blue;

            &:before {
              border-bottom: 15px solid $picton-blue;
            }
          }


          &.lightbulb {
            background-color: $carissma;

            &:before {
              border-bottom: 15px solid $carissma;
            }
          }

        }
      }
    }

    .line {
      max-width: 81px;
      width: 100%;
      height: 1px;
      border: dashed 2px $pale-slate;

    }
  }

  footer {
    width: 100%;
    min-height: 140px;
    background-color: $white;
    @include flex-row-center;
    margin-top: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-bottom: 5px solid #6451a3;

    .btn {
      max-width: 206px;
      width: 100%;
      height: 54px;
      margin: 0 10px;
      @include flex-row-center;
    }
  }

}


