.customSelect {

  width: 100%;

  .MuiInputBase-input {
    padding: 0;
  }

  &:before {
    display: none;
  }

  .MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.31;
    color: $charcoal-grey;
    background-color: transparent;
  }

  &.MuiInput-underline:after {
    display: none;
  }


}


.MuiPopover-paper {
  ul {
    li.MuiListItem-button {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.31;
      color: $charcoal-grey;
    }
  }
}
