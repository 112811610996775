
.method-statement {

  .method-statement-wrapper {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    padding-bottom: 112px;
    min-height: calc(100vh - 318px);

    .method-statement-container {
      max-width: 1239px;
      width: 100%;
      margin: auto;


      .method-statement-header {
        text-align: center;
      }

      .circle-progress-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .circle-progress-container {
          @include flex-row-center;
          flex-direction: column;

        }

        .download-wrapper {
          position: relative;

          .download-container {
            width: 225px;
            left: -225px;
            top: -32px;
            position: absolute;

            .download-item {
              display: flex;
              margin-bottom: 20px;
              cursor: pointer;

              span {
                margin-left: 20px;
                font-size: 15px;
                font-stretch: condensed;
                letter-spacing: 0.14px;
                color: $charcoal-grey;
              }
            }


          }
        }

      }


      .method-statement-body {
        padding: 0 15px;

        .card {
          @include card-big-border;
          max-width: 100%;

          h4 {
            font-size: 26px;
            letter-spacing: 0.25px;
            text-align: center;
            color: $emperor;
          }

          p {
            font-size: 15px;
            font-weight: 300;
            line-height: 1.4;
            letter-spacing: normal;
            color: $charcoal-grey;
          }
        }

        .card-our-analysis {
          min-height: 271px;

          font-size: 15px;
          font-weight: 300;
          line-height: 1.4;
          color: $charcoal-grey;

          .card-comment-container {
            width: 100%;
            padding-bottom: 35px;
          }


          .our-analysis-list-block {
            ul {
              list-style: circle;
              margin-top: 0.5em;

              li {
                font-size: 15px;
                font-weight: 300;
                line-height: 1.4;
                color: $charcoal-grey;
              }


            }
          }

          .our-analysis-list-block.ra {
            padding-bottom: 2em;
          }

          .risk-assessment-box {
            height: 50px;
          }

        }

        .card-comments {
          min-height: 271px;
          display: flex;
          flex-direction: column;

          .btn {
            display: flex;
            justify-content: center;
            align-items: center;

            font-family: Roboto;
            font-size: 15px;
            font-stretch: condensed;
            letter-spacing: 0.21px;
            color: $charcoal-grey;

            span {
              margin-left: 10px;

              img {
                margin-right: 10px;
              }
            }
          }

          .no-comment {
            font-size: 26px;
            letter-spacing: 0.25px;
            text-align: center;
            color: #c6c5c6;
            margin-top: 44px;
            margin-bottom: 76px;
          }
        }

        .list-highlighters {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-top: 47px;

          .highlight-item {
            margin-bottom: 30px;
            font-size: 15px;
            color: $charcoal-grey;
            width: 493px;

            .item-title {

              font-weight: 500;
              line-height: 1.4;
              margin-bottom: 8px;
            }

            .item-subtitle {
              line-height: 1.4;
              padding-bottom: 7px;
              margin-bottom: 5px;
              border-bottom: solid 1px $light-grey;
            }

            .sidebar-card-footer {
              font-size: 14px;
            }
          }

        }
      }
    }

  }

  .block-recommendation-briefings {
    padding-bottom: 50px;

    .download-item {
      padding: 6px 0;

      .btn-title {
        padding-left: 15px;
      }

      .circle-progress {
        color: $fountain-blue;
      }
    }
  }

  .method-statement-footer {
    text-align: center;

    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .btn-container {
      display: flex;
      justify-content: center;
      margin-bottom: 37px;

      .btn {
        width: 206px;
        height: 54px;
        font-size: 14px;

        &.btn-secondary {
          margin-right: 10px;
        }

        &.btn-primary {
          margin-left: 10px;
        }
      }
    }

    .btn-container-author {
      margin-bottom: 0;
    }
  }


  @media only screen and (max-width: 585px) {
    .method-statement-wrapper {
      .method-statement-header {
        .circle-progress-wrapper {
          flex-direction: column;

          .circle-element {
            .method-statement {
              font-size: 30px;
            }

          }

          .download-wrapper {

            .download-container {
              position: relative;
              left: 0px;
              top: 0px;
              margin-top: 20px;

              .download-item {
                margin-bottom: 15px;
              }

            }
          }

        }
      }
    }
  }

  @media only screen and (max-width: 330px) {
    .method-statement-header {
      .circle-element {
        .method-statement {
          font-size: 22px;
        }

      }
    }
  }


}