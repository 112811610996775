
.best-practice-section {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 100px;

  .best-practice-content {
    max-width: 1240px;
    height: 400px;
    padding: 0 70px;
    background-color: $white;
    margin: auto;
    border: solid 1px $light-grey;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);

    @media (max-width: 750px) {
      height: 560px;
    }
  }

  p.subtitle {
    color: $charcoal-grey;
    max-width: 810px;
    margin: auto;
    font-weight: 300;
    font-size: 15px;
  }

  p.subtitle-left {
    text-align: left;
    text-transform: capitalize;
    font-size: 15px;
  }


  .input-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .inputs-col {
      width: 49%;

      .input-item {
        width: 100%;
        height: 50px;
        border: 1px solid #d0021b;
        margin: 10px 0;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 300;
        color: $charcoal-grey;
        font-size: 15px;

        &.correct {
          border: 1px solid #5EBFB2;
        }
      }

      @media (max-width: 750px) {
        width: 100%;
      }

    }
  }


  @media (max-width: 750px) {
    margin-top: 125px;
  }
}

