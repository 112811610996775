.nav-content {

  .nav-item-header {
    height: 102px;
    padding-top: 48px;
    padding-right: 39px;
    text-align: right;

    span {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.38;
      letter-spacing: 0.15px;
      margin-right: 10px;
      color: $white;
    }

  }

  .nav-item {
    display: block;
    padding: 18px 18px 18px 34px;
    color: white;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 300;
    border-bottom: solid 1px $pale-sky;
    outline: none;


    &.active {
      background-color: $pale-sky;
      border-top: solid 1px rgba(242, 243, 244, 0.1);
      border-bottom: solid 1px rgba(242, 243, 244, 0.1);

    }


  }

  a:nth-child(2) {
    border-top: solid 1px $pale-sky;
  }

  a.active:nth-child(2) {
    border-top: solid 1px rgba(242, 243, 244, 0.1);
  }
}