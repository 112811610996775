
.home-container{


  .container-table{
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;
    padding-bottom: 50px;



    #main-table{
      margin-top: 25px;
      overflow-x:auto;

      border-collapse: separate;
      border-spacing: 1px;


      th{
        padding: 18px 0px;
        text-align: center;
        background-color: $regent-gray;
        color: $white;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        &.status{
          background-color: $carissma;
        }

      }

      td{
        padding: 16px;
        vertical-align: top;

        &.ref{
          width: 93px;
          border-left: solid 1px $light-grey;
        }
        &.location{
          width: 180px;
        }
        &.project{
          width: 200px;
        }
        &.aproval{
          width: 140px;
        }
        &.date{
          width: 155px;
        }
        &.improvement{
          width: 203px;
          text-align: center;


          .first-item{
            min-width: 26px;
            &.exist{
              background-color:$picton-blue;
            }
          }
          .second-item{
            &.exist{
              background-color:$carissma;
            }
          }
          .third-item{
            float: right;
            min-width: 45px;
            //font-weight: bold;
          }

          .second-item-empty{
            display: block;
            min-width: 26px;
          }
          .first-item, .second-item{

            &.exist{
              @include flex-row-center;
              border-radius: 50%;
              min-width: 26px;
              height: 26px;
              color: $white;

            }
          }
          justify-content: space-between;
        }
        &.status{
          width: 268px;
          text-align: right;
          justify-content: space-between;

          .progress-bar-container{
            width: 100%;
            margin-right: 15px;

          }



        }


        border-right: solid 1px $light-grey;


        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;


      }

      // small table
      @media (max-width: 1240px) {
        padding-right: 15px;
        padding-left: 15px;
      }

    }

    .pagination-container{
      padding: 15px 0;
      ul li button, .MuiPaginationItem-ellipsis{
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        color: $charcoal-grey;
      }


    }


  }
}

tr, th, td{
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto; /* default value */
  flex-grow: 1;
}
th{
  border-right: solid 1px $white;
  justify-content: center;



  &:nth-child(1){
    min-width: 93px;
  }
  &:nth-child(2){
    min-width:180px;
  }
  &:nth-child(3){
    min-width:200px;
  }
  &:nth-child(4){
    min-width:140px;
  }
  &:nth-child(5){
    min-width:155px;
  }
  &:nth-child(6){
    min-width:203px;
  }
  &:nth-child(7){
    min-width:268px;
    border-right: solid 1px $carissma;;
  }
}

tr{
  border-bottom: solid 1px $light-grey;

  &:last-child{
    border-bottom: solid 2px #74848f;
  }
}



.bold{
  font-weight: bold;
}

.react-tooltip{

  box-shadow: 2px -2px 4px 0 rgba(0, 0, 0, 0.05);
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
}

.__react_component_tooltip{
  border-radius: 16px!important;
}


.data-range-backdrop{
  background-color: transparent;
  z-index: -1;
  position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  cursor: default;
}


.rotation{
  transform: rotate(180deg);
}



.container-table-filters{
  margin-top: 74px;
  display: flex;
  flex-direction: row;
  .filters{
    height: 59px;
    border: solid 1px $light-grey;
    background-color: #ffffff;
    padding: 9px 18px 14px 15px;

    .filter-title{
      font-size: 11.5px;
      font-stretch: condensed;
      letter-spacing: 0.19px;
      color: $scorpionapprox;
      text-transform: uppercase;
      display: flex;

      img{
        margin-right: 5px;
      }
    }
    .filter-content{
      width: 100%;
      display: flex;
      justify-content:space-between;
      align-items: flex-start;
      margin-top: 3px;
      input{
        width: 100%;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.31;
        color: $mountain-mist;

        &::placeholder {
          font-size: 16px;
          font-weight: 300;
          line-height: 1.31;
          color: $mountain-mist;
        }

      }

      .search{
        margin-top: -10px;
      }
      span{
        font-size: 16px;
        font-weight: 300;
        line-height: 1.31;
        color: $charcoal-grey;
      }
    }
    .data-range-container{
      position: absolute;
      z-index: 2;
      margin-left: -15px;
      margin-top: 15px;

    }
  }

  .filter-by-ref{
    width: 355px;
  }

  .filter-by-client-left{
    width: 49.5%;
    margin-right: 0.5%;
  }

  .filter-by-client-right{
    width: 49.5%;
    margin-left: 0.5%;
  }

  .filter-by-name{
    width: 320px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .filter-by-status{
    width: 266px;
    margin-right: 10px;
  }

  .filter-by-date{
    width: 270px;

  }


  // small table
  @media (max-width: 1240px) {
    padding-right: 15px;
    padding-left: 15px;
  }

}

.unsubmitted-circle{
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: $pale-slate;
}

.btn-data-range-reset{
  position: absolute;
  bottom: 4px;
  right: 25px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
}