.dashboard-table {

  .main-container {
    max-width: 1240px;
    margin: 0 auto;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;

    .container-table-filters {
      &.ontology {
        justify-content: center;

        .filter-by-ref {
          max-width: 455px;
          width: 100%;
        }
      }
    }

    .table-wrapper {

      padding-top: 35px;

      .paper-container {
        width: 100%;

        .table-container {
          min-width: 650px;

          .table-row-header {
            th {
              background-color: $regent-gray;
              color: $white;
              border-right: solid 1px $regent-gray;
            }

          }

          .row {

            .tableCell-ontology {
              vertical-align: top;
            }

            .btn-children {
              text-align: left;

            }

            .input-children {
              width: 100%;
              padding: 2px;
              background-color: transparent;

              &:focus, &:hover {
                background-color: transparent;
              }

              &:focus {
                border-bottom: solid 0.5px $mountain-mist;
              }
            }

            &.removed {
              td {
                color: $mountain-mist;
              }

            }

            .actions-container {
              .btn {
                padding: 2px 10px;
              }
            }
          }


        }

      }

      @media (max-width: 1240px) {
        padding-right: 15px;
        padding-left: 15px;
      }

    }
  }
}


.form-create-edit-Wrapper {
  background-color: $white;
  max-width: 760px;
  width: 100%;

  .fields-wrapper {
    max-width: 600px;
    margin: 100px auto 50px;
    //padding-top: ;

    .custom-input {
      margin-bottom: 20px;
    }
  }

  .select-container {
    min-width: 200px;
    background-color: #F2F3F4;
    padding: 2px 12px;
    margin-bottom: 20px;

    .select {
      width: 100%;

      &:after, &:before {
        display: none;
      }

      #simple-select {
        background-color: transparent;

        &:focus, &:hover {
          background-color: transparent;
        }
      }
    }


    .label, .menu-item-status div, #simple-select {
      font-size: 14px;
      font-family: Roboto;
      font-weight: 300;
      color: #2F2E2F;
      letter-spacing: 0.15px;
    }

    label#simple-select-label {
      font-size: 11px;
      padding-top: 3px;
    }

    .menu-item-status {
      font-size: 14px;
    }
  }

  .form-footer-container {
    @include flex-row-center;
    margin-top: 45px;
    margin-bottom: 37px;


    .btn {
      max-width: 206px;
      width: 100%;
      height: 45px;
      //padding: 15px 0;
      margin: 0 10px;

      font-size: 15px;
      font-weight: bold;
      font-stretch: condensed;
      letter-spacing: 0.22px;
      box-shadow: none;

      &.updloading {
        background-color: $light-grey;
        border: solid 1px $pale-slate;
        color: $rolling-stone;

      }
    }

  }

  .icon-menu-modal {
    float: right;
    margin-top: 20px;
    margin-right: 20px;

  }

}


