

.header-container{

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  min-height: 112px;
  display: flex;
  .header-logo{
    width: 151px;
    height: 51px;
  }

  nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    .btn-icon-left{
      padding: 10px;

      span{
        padding: 0 14px;
        font-size: 14px;
        font-family: Roboto;
        font-weight: bold;
        font-stretch: condensed;
        letter-spacing: 0.2px;
      }

    }

    .save-progress{
        padding: 8px 19px 10px 13px;
    }

    .btn-menu{
        margin-left: 32px;
        //cursor: pointer;

    }


  }


}

@media only screen and (max-width: 880px) {

  .header-container{
    .header-title{
      display: none;
    }
  }

}