.uploadFileWrapper {

  padding: 25px;

  .icon-menu-modal {
    float: right;
    color: red;
  }

  h2 {
    text-align: center;
  }

  .uploadFile-container {

    max-width: 941px;
    margin: auto;
    display: flex;
    flex-direction: column;


    .fields-wrapper {
      display: flex;
      justify-content: space-between;

      .autocomplete {
        width: 32%;

        .textField {

          label {
            color: $charcoal-grey;
          }

          fieldset {
            &.MuiOutlinedInput-notchedOutline {
              border-color: rgba(0, 0, 0, 0.23);
              border-width: 1px;
            }

          }
        }
      }

    }

    .dropzone-container {
      display: flex;
      justify-content: space-between;
      //max-width: 941px;
      margin: 37px auto 20px;
      width: 100%;

      .sub-header {
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    hr {
      width: 100%;
    }

    .uploadFile-button-container {
      @include flex-row-center;
      margin-top: 45px;
      margin-bottom: 37px;


      .btn-secondary, .btn-primary {
        max-width: 206px;
        width: 100%;
        padding: 17px 0;
        margin: 0 10px;

        font-size: 15px;
        font-weight: bold;
        font-stretch: condensed;
        letter-spacing: 0.22px;
        //text-align: center;
        //color: $charcoal-grey;
        box-shadow: none;

        &.updloading {
          background-color: $light-grey;
          border: solid 1px $pale-slate;
          color: $rolling-stone;

        }


      }


    }

  }


}


.form-create-edit-Wrapper {

  .form-container {

  }
}