.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .selector-label {
    margin-right: 10px;
    font-size: 0.875rem;
  }

  .MuiSelect-selectMenu {
    font-size: 0.875rem;
  }

  .MuiInput-underline:after {
    display: none;
  }
}


.dialogBox4354 {

  .MuiPaper-rounded {
    border-radius: 0;

    .btn {
      width: 100px;
      height: 30px;
      font-size: 14px;
    }

  }


}