
.dashboard-container {
  width: 100%;
  height: 100%;

  .main-container {
    width: 100%;
    height: calc(100% - 112px);

    display: flex;
    justify-content: center;
    align-items: center;

    .main-wrapper {

      margin-top: -20%;
      text-align: center;

      h4 {
        margin-top: 0px;
        margin-bottom: 10px;
      }

      p {
        color: $charcoal-grey;
        font-family: Roboto;
      }

      .btn-container {

        width: 420px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .btn {
          width: 200px;
          padding: 15px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .badge {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $mountain-mist;
            padding: 5px;
            border-radius: 50%;
            color: $white;
            font-size: 13px;
          }

          &:hover {
            .badge {
              background-color: $white;
              color: $mountain-mist;
            }
          }

        }
      }

    }


    @media only screen and (max-width: 450px) {

      .main-wrapper {

        width: 100%;
        padding: 0 20px;
        margin-top: -10%;

        .btn-container {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;

          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}