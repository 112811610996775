.modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  //width: 70%;
  //width: 1240px;
  max-width: 1240px; //card comment
  width: 100%; //card comment
  padding: 16px;
  //left: 15%;
  //top: 15%;
  top: 5%;
  //top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  max-height:90%;
  overflow-x:scroll;
}

@media (min-width: 600px) {
  .modal {
    //width: 500px;
    //left: calc(50% - 250px);
  }
}



.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  opacity: 0.94;
  background-color: $charcoal-grey;;
}