
main {
  .percentage-container {
    width: 100%;
    height: 38px;
    background-color: #8899a4;

    .percentage-content {
      max-width: 883px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;

      .progress-bar {
        box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        border: none;
        background-color: $light-grey;

        .filler {
          background-image: linear-gradient(to bottom, #97e1d9, $fountain-blue);
        }

        margin: 0 10px;

      }

      span.start-end-value {
        font-size: 15px;
        letter-spacing: 0.14px;
        color: $porcelain;
      }
    }
  }

  .control-measure-container {
    max-width: 883px;
    width: 100%;
    margin: 70px auto;

  }
}