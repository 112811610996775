.home-container{
  background-color: $porcelain;
  height: 100vh;
  overflow: auto;

}

.mainHome{
  left: 0px;
  position: relative;
  width: 100%;
  margin-left: 0;
  transition: all 0.3s ease;
  background: #ffffff;
  z-index: 0;
  &.mainHome-open{
    left: -409px;
  }

  .nav-container{

    position: fixed;
    width: 409px;
    height: 100%;
    top: 0;
    right: -409px;
    //right: 0px;
    transition: all 0.3s ease;
    //padding: 80px 0 20px 0;
    overflow-y: auto;
    background: $gray-nav;
    //background-size: cover;
    z-index: 9999999;
    box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.12);

    &.nav-container-open{
      right: 0px;
    }
  }
}

body{
  overflow:hidden;
}