.card-comment {

  @include card-big-border;
  max-width: 100%;

  .card-comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {

      font-size: 15px;
      font-weight: normal;
      font-stretch: condensed;
      letter-spacing: 0.14px;
      color: $hopbush;
    }
  }

  .card-comment-container {

    font-size: 15px;
    font-weight: 300;
    line-height: 1.4;
    color: $charcoal-grey;
    width: 100%;
    height: 211px;
    resize: none;

  }
}

.card-comment-modal {

  .card-comment-modal-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 18px 28px 20px;

    .intuety-logo {
      width: 126px;
    }
  }

  .card-comment-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding-bottom: 110px;

    h2 {
      margin-bottom: 0;
    }

    h4 {
      margin-top: 12px;
    }

    .card-comment-modal-content-body {
      max-width: 451px;
      width: 100%;

      textarea {
        width: 100%;
        height: 333px;
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px #f3f3f3;
        background-color: #fbfbfb;
        resize: none;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0.14px;
        color: $charcoal-grey;
        padding: 18px;


      }

      hr {
        border-top: 1px solid $light-grey;
        margin: 60px 0;
      }

      .btn-container {
        display: flex;
        justify-content: space-between;

        .btn {
          width: 45%;
          padding: 17px 0;
          box-shadow: none;

        }
      }
    }


  }
}


.card-comment-empty {
  width: 100%;
  text-align: center;

  @include flex-row-center;

  button {
    font-size: 14px;
    color: $charcoal-grey;
    @include flex-row-center;

    span {
      margin-left: 5px;
    }
  }

  margin: 0 auto 47px;

}