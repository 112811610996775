.summary-container {
  background-color: $white;
  min-height: 100%;

  .circle-progress-container {
    @include flex-row-center;

    &.small {
      margin-bottom: 30px;
    }

  }

  .summary-content {
    max-width: 1242px;
    margin: auto;

    .summary-content-header {

      display: flex;
      flex-direction: column;


      h1 {
        text-align: center;
        color: $emperor;
      }

      h4 {
        font-size: 21px;
        text-align: center;
      }

    }

    .card-main {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      background-color: $porcelain;
      padding: 20px 22px;
      margin-bottom: 20px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

      .activities-title {
        font-size: 13px;
        font-stretch: condensed;
        letter-spacing: 0.12px;
        color: $hopbush;
        padding: 0;
        margin: 0 0 2px 0;
      }

      .activities-text-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;


        .oval {
          display: inline-block;
          //flex-basis: auto;
          flex-shrink: 0;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: $fountain-blue;
          margin-right: 8px;
          margin-top: 5px;

        }


        .oval-removed {
          background-color: #a3a3a4;
        }

      }

      .activities-text {
        display: inline;
        font-size: 14px;
        line-height: 1.5;
        padding: 0;
        margin: 0;

      }


      .removed {
        color: #a3a3a4;
      }


      .card-description {
        width: 20%;
        min-width: 248px;
        padding: 15px;
        margin-right: 11px;
      }

      .card-values-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        .card-values {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 20px;


          .card-value {
            width: 40%;
            padding: 15px 15px;
            margin: 0 11px;

          }
        }
      }

    }

    .summary-content-footer {
      width: 100%;
      text-align: center;

      .btn-continue {
        padding: 17px 23px;
        margin: 46px auto 68px;
        font-size: 15px;
        font-weight: bold;
        display: inline-block;

      }
    }


  }


  @media only screen and (max-width: 1242px) {
    .summary-content {
      margin: 0px 25px;

      .card-main {
        width: 100%;
        margin: auto;
      }
    }
  }

  @media only screen and (max-width: 760px) {

    .summary-content {
      .card-main {
        width: 100%;
        margin: 0px auto 25px;
        padding: 10px 12px;
        display: flex;
        flex-wrap: wrap;


        .card-description {
          width: 100%;
          min-width: 100%;
          padding: 15px 7px;
          margin-right: 0px;

          &.empty {
            display: none;
          }
        }


        .card-values-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;

          .card-values {
            width: 100%;
            margin-bottom: 0px;
            justify-content: space-between;
            flex-wrap: wrap;

            .card-value {
              width: 49%;
              padding: 15px 13px;
              margin: 6px 0px;

              &:nth-child(1) {
                //margin-right: 3px;
              }

              &:nth-child(2) {
                //margin-left: 3px;
              }

            }
          }
        }

      }
    }

    .circle-progress-container {
      margin-top: 0px;
    }
  }

  @media only screen and (max-width: 450px) {

    .summary-content {
      .summary-content-header {

        h1 {
          font-size: 30px;
        }

      }
    }

  }

  @media only screen and (max-width: 350px) {

    .summary-content {
      .summary-content-header {

        h1 {
          font-size: 22px;
        }

      }
    }
  }

}


//circle element inside the progress bar
.circle-element {
  text-align: center;

  h1, h2, .big-title {
    font-family: Roboto;
    font-size: 52px;
    letter-spacing: 0.5px;
    text-align: center;
    font-weight: normal;
    color: $emperor;
    margin: 0;
    padding: 0;

  }

  h2 {
    font-size: 40px;
    letter-spacing: 0.11px;
    margin-top: 0;
    margin-bottom: -6px;
  }

  .big-title {
    font-size: 39.3px;
  }

  .method-statement {
    font-size: 40px;
  }


  .title {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.47;

    text-align: center;
    color: $charcoal-grey;

    &.bold {
      font-weight: 500;
    }


  }

  .sub-title {
    font-size: 15px;
    font-stretch: condensed;
    line-height: 1.13;
    letter-spacing: 0.21px;
    text-align: center;
    color: $emperor;

    &.bold {
      font-weight: 500;
    }
  }

  .multiple-line {
    display: block;
  }

  .pm-0 {
    padding: 0;
    margin: 0;
  }


  @media only screen and (max-width: 350px) {

    .sub-title, .title {
      font-size: 12px;
    }

  }


}


