.reactGradientProgress {
  display: inline-block;
  border-radius: 100%;
  position: relative;

}
.reactGradientProgressPercentage {
  position: absolute;
  height:100%;
  width: 100%;
  margin: 0px auto;

}

.reactGradientProgressPercentageSpan {
  position: absolute;
  left: 19%;
  top: 19%;
  height:62%;
  width: 62%;

  @include flex-row-center;
  flex-direction: column;
  text-align: center;
  background-image: linear-gradient(160deg, LightGray, white, white);//linear-gradient('#e3e4e2', white);
  //background-image: linear-gradient(to bottom right, LightGray, white, white);//linear-gradient('#e3e4e2', white);
  border-radius: 50%;





}

.progressCircleBar {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}


.test {
  display: inline-block;
  margin: 2em auto;
  border: 2px solid #000;
  font-size: 2em;
}