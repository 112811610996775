.upload-document-section{
  background: $white;
  margin-top: 100px;
  text-align: center;
  padding-bottom: 50px;

  .upload-document-container {
    height: 350px;
    padding: 20px;
    background-color: $porcelain;
    max-width: 1000px;
    margin: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;

    .oval {
      width: 86px;
      height: 86px;
      padding: 24px 29px 27px 28px;
      background-color: #5ebfb2;
      border-radius: 50%;
      position: absolute;
      top: -20px;
      left: 50%;
      margin-left: -43px;
    }
    .upload-document-item {
      width: 32%;
      height: 100%;
      background-color: $white;
      padding: 0 3%;
      display: flex;
      flex-direction: column;
     justify-content: center;

      &.correct{
        border: solid 2px #5ebfb2;
      }

      .title{
        color: $white;
        padding: 12px;
        margin: 18px 0;
        &.orange{
          background-color: #f88a3e;
        }
        &.green{
          background-color: #5ebfb2;
        }
        &.gray{
          background-color: #687781;
        }
      }
      //@media (max-width: 750px) {
      //  width: 99%;
      //}
    }
  }


}

@media (max-width: 750px) {

  .upload-document-section {
  height: 1100px;

    .upload-document-container {
      height: 100%;

      .upload-document-item {
        width: 99%;
        margin-bottom: 10px;
        height: 300px;


      }

    }
  }
}



