.progress-bar {
  position: relative;
  height: 12px;
  width: 100%;
  border: solid 1px $pale-slate;
  z-index: 1;


  .filler {
    background-color: $topaz;
    height: 100%;
    border-radius: inherit;

  }

  .filler2 {
    position: absolute;
    margin-top: -12px;
    background-image: linear-gradient(to bottom, #75828b, #5a6872);
    height: 100%;
    border-radius: inherit;
    z-index: -1;

  }

  .circle {
    position: absolute;
    top: -10px;
    width: 29px;
    height: 29px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: $white;
    border-radius: 50%;
  }

  .percentage-box {
    left: -12px;
    top: -10px;
    width: 38px;

    background-color: $carissma;
    position: absolute;
    margin-top: -40px;
    color: $white;
    padding: 3px 0;
    border-radius: 4px;

    font-size: 12px;
    font-weight: 500;
    line-height: 2.1;

    &:before {

      position: absolute;
      top: 30px;
      left: 13px;
      content: "";
      width: 0;
      height: 0;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid $carissma;


    }

  }


}

.progress-bar-empty {
  background-image: linear-gradient(to bottom, #eeeeee, #d8d8d8);
}

