
// Colours

$white: #ffffff;
$porcelain: #F2F3F4; //background color
$regent-gray: #8799A5; // tooltips color
$gray-nav: #798994; // tooltips color
$marine-blue: #002855; // Secondary Text (widget)
$charcoal-grey: #2F2E2F; // Text
$butterfly-bush: #6451a3; // Secondary highlight
$carissma: #ed7cb1; // Main highlight
$fountain-blue: #5EBFB2 ; // CTA Buttons
$scorpionapprox: #5e5d5e ; //  Buttons hover dark
$topaz: #14b29e ; // CTA Buttons
$dark-grey: #3e4243 ; // CTA Buttons
$light-grey: #e3e4e2; // CTA Buttons
$pale-sky: #687781; // border bottom item nav
$mountain-mist: #9D9C9E; // placeholder search
$alabaster: #F9F9F9; // background dropzone
$rolling-stone: #808285; // color btn dropzone upload
$pale-slate: #c6c5c6; // border btn dropzone upload
$picton-blue: #4DAAF1; //
$emperor: #4d4c4d; // h1 color
$hopbush: #C86995; // title header card summary
$tomato: tomato; // form error



// Global Styles
$background-white: $porcelain!default;
$body-text: $dark-grey!default;
$body-text-secondary: $marine-blue!default;
$tooltips-color: $regent-gray!default;

// Main Colours
$accent-primary: $carissma!default;
$accent-secondary: $butterfly-bush!default;
$btn-primary: $fountain-blue!default;
$btn-primary-hover: $topaz!default;
$btn-secondary: $white!default;
$btn-secondary-hover: $scorpionapprox!default;
$base-placeholder-color: $charcoal-grey!default;



// Settings
$base-font-family:"Roboto", Helvetica, sans-serif;
$base-font-family-alt: "Roboto", Arial, sans-serif;
$base-font-size: 25px;
$base-line-height: 44px;
$base-icon-size : 28px;
$base-width: 1920px;
$base-mwidth: 375px;


// base

@import "sass/base/_base";
@import "sass/base/_normalize";
@import "sass/base/_default";
@import "sass/base/_flexlayout";
@import "sass/base/_print";
@import "sass/common/common";
@import "sass/common/common";
@import "sass/animation/_animation.scss";

// templates

@import "sass/templates/login";
@import "sass/templates/home";
@import "sass/templates/activities";

@import "js/templates/index.scss";
@import "js/templates/uploadFile/uploadFile";
@import "js/templates/summary/_summary.scss";



// components

@import "js/components/_index";


//react-redux-toastr style
@import "sass/toastr/index";


.toast-message{
  position: absolute;
  z-index: 9;
  margin-top: 10px;
  right: 5px;
}