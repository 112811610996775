/*!

*  Last Updated: 15/04/20
*  By: Helder Correia
*
*  Notes:
* Styles generic
*
*
*/

/* ================================
Default styles Layout Elements
================================ */

@mixin title-base1 {
  //font-family: Roboto;
  font-weight: 300;
  letter-spacing: 0.3px;
}

@mixin title-base2 {
  //font-family: Roboto;
  font-weight: 300;
  letter-spacing: 0.23px;
  color: $charcoal-grey;
}


h1{

  font-size: 32px;
  color: $marine-blue;
  @include title-base1;


}


h2{

  font-size: 30px;
  color: $charcoal-grey;
  @include title-base1;

}
h3{

  font-size: 24px;
  @include title-base2;

}

h4{

  font-size: 22px;
  @include title-base2;

}

.sub-header{
  //font-family: Roboto;
  font-size: 18px;
  font-stretch: condensed;
  letter-spacing: 0.17px;
  color: $charcoal-grey;
}

input{
  color: $charcoal-grey;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $charcoal-grey;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $charcoal-grey;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $charcoal-grey;
}



// buttons
.btn-primary{
  background-color: $btn-primary;
  //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: $white;
  font-family: Roboto;

  &:hover{
    background-color: $btn-primary-hover;
  }
}

.btn-secondary{
  background-color: $btn-secondary;
  //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: $charcoal-grey;
  border: solid 1px $scorpionapprox;
  font-family: Roboto;


  &:hover{
    background-color: $btn-secondary-hover;
    border: solid 1px $btn-secondary-hover;
    color: $white;

  }
}

@mixin flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin card-big-border {
  max-width: 883px;
  padding:0  25px;
  margin: 10px auto 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 22px rgba(136, 153, 164, 0.60);
}